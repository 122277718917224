import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Pagination, PaginationSizeFilter } from "../CommonComponents/Pagination";
import TableSearchBar from "../CommonComponents/TableSearchBar";
import { getTestsByStatus } from "../../../functions/api/academicAPIs/getTestsByStatus";
import ProgressBar from "../CommonComponents/ProgressBar";
import PerformanceDrawerContent from "./PerformanceDrawerContent";
import CommonDrawer from "../../commonComponents/CommonDrawer";


const Row = (props) => {
  const { row, index } = props;

  const [state, setState] = useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };


  return (
    <React.Fragment>
      <TableRow
        className={`${index % 2 == 1 ? 'colored' : ''}`}
        sx={{ "& > *": { borderBottom: "unset" } }}
      >
        <TableCell component="th" scope="row" align='center' className='tableCell customCell'>
          {row?.rank}
        </TableCell>
        <TableCell component="th" scope="row" align='center' className='tableCell'>
          {row?.userName}
        </TableCell>
        {
          <TableCell component="th" scope="row" style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem', width: '100%', alignItems: 'center', justifyContent: 'center' }} align="center" className='tableCell'>
            <ProgressBar progress={row?.percentage} maxScore={100} />
            <span style={{ fontSize: '0.7rem' }}>{row?.percentage?.toFixed(2)}/{100}</span>
          </TableCell>
        }

        <TableCell component="th" scope="row" align='center' className='tableCell'>
          {row?.tests?.length}
        </TableCell>
        {
          <TableCell component="th" scope="row" align="center" className='tableCell'>
            <CommonDrawer toggleDrawer={toggleDrawer} state={state} component={<PerformanceDrawerContent data={row?.tests} rowData={row}/>} />
            <button className="btn" onClick={toggleDrawer("right", true)}>Get Details</button>
          </TableCell>
        }
      </TableRow>
    </React.Fragment>
  );
}

const PerformanceContentTable = ({ data, total, size, setSize, page, setPage }) => {
  const [searchValue, setSearchValue] = useState();
  const [value, setValue] = useState("COMPLETED");

  const handleSizeChange = (event) => {
    setSize(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handlePageChange = (change) => {
    if (change && page < Math.ceil(+total / +size)) {
      setPage((prev) => prev + 1);
    } else if (!change && page > 1) {
      setPage((prev) => prev - 1);
    }
  };

  return (
    <StyledInterviews>
      <TableContainer component={Paper} className="tableBox">
        <span className='title'>Performance Leaderboard</span>
        <SearchBarContainer>
          <TableSearchBar value={searchValue} setValue={setSearchValue} />
        </SearchBarContainer>
        <Table aria-label="collapsible table">
          <TableHead className="tableHead">
            <TableRow>
              <TableCell align='center' className='tableCell'>Rank</TableCell>
              <TableCell align='center' className='tableCell'>UserName</TableCell>
              <TableCell align='center' className='tableCell'>Overall Percentage</TableCell>
              <TableCell align='center' className='tableCell'>Number of Tests Attempted</TableCell>
              <TableCell align="center" className='tableCell'>Details</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="tableBody">
            {data?.map((row, index) => (
              <Row key={index} row={row} index={index} />
            ))}
          </TableBody>
        </Table>
        <div className="paginationBox">
          <PaginationSizeFilter
            size={size}
            handleSizeChange={handleSizeChange}
          />
          <Pagination
            total={total}
            size={size}
            page={page}
            handlePageChange={handlePageChange}
            setPage={setPage}
          />
        </div>
      </TableContainer>
    </StyledInterviews>
  );
};

export default PerformanceContentTable;


const StyledInterviews = styled.div`
  display: flex;
  width: 100%;
  margin: 0.5rem auto;

  .colored {
    background-color: #ececec;
  }

  .paginationBox {
    display: flex;
    justify-content: end;
    gap: 2rem;
    margin: 1rem 3rem 1.5rem 0;
  }

  .tableBox {
    box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.20);
    border-radius: 0.5rem;
    padding-top: 1rem;


    .title {
      padding-left: 1.2rem;
      font-size: 0.9rem;
      font-weight: 600;
    }
  }
  


  .MuiTableCell-root {
    border: none;
  }

  .MuiTableRow-root {
    border-bottom: none;
  }

  .btn {
    background-color: var(--academicLavendar);
    padding: 0.5rem 0.8rem;
    border: none;
    color: var(--white);
    font-size: 0.9rem;
    font-weight: 600;
    border-radius: 0.5rem;
    cursor: pointer;
    font-family: var(--font);
  }

  .tableHead {
    background-color: var(--lightAcademicLavendar);
    width: 100%;
  
    .tableCell {
      font-size: 0.9rem;
      font-weight: 500;
      font-family: var(--font);
      color: var(--color);
    }
    
  }
  
  .tableBody {
    width: 100%;
  
    .tableCell {
      font-size: 0.8rem;
      font-weight: 400;
      font-family: var(--font);
      color: var(--color);
    }

    .customCell {
      display: flex;
      gap: 0.5rem;
      align-items: center;
      justify-content: center;

      .icon {
        width: 1rem;
        height: 1rem;
        cursor: pointer;
      }
    }
  }

  .selected {
    background-color: #d9fbf9;
    color: white;
  }
`;



const SearchBarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 96%;
  margin: 1rem auto 0.5rem auto;
  height: 3rem;
  background-color: var(--white);
  border-radius: 0.5rem;;
  padding: 0rem 1rem;
  gap: 1rem;

`
