import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import website from '../../../assets/icons/websiteIcon.png'
import editIcon from '../../../assets/icons/editBlack.png'
import { toast } from 'react-toastify';
import cameraIcon from '../../../assets/icons/Profile/camera.png'
import { publicFileUpload } from '../../../functions/api/globalFunctions/publicFileUpload';
import ProfileBasicDetails from '../CommonComponents/ProfileBasicDetails';
import ProfileContactDetails from '../CommonComponents/ProfileContactDetails';
import ProfileAcademicDetails from '../CommonComponents/ProfileAcademicDetails';
import AcademicModalHoc from '../CommonComponents/AcademicModalHoc';


const user = {
    "profilePhoto": "",
    "name": "Abhi Pandey",
    "portfolio": "https://portfolio.example.com",
    "spocContact": "8002078895",
    "spocName": "Abhi Pandey",
    "spocEmail": "abhipandeydemo@gmail.com",
    "address": "123 Main St",
    "city": "Anytown",
    "userName": "abhipandey14",
    "userType": "Student",
    "enrolledCourses": ["Science", "Math"],
    "courseProgress": "50%",
    "school": "M.I.P.S",
    "std": "10th Grade",
    "boardOfStudy": "CBSE",
    "studyPattern": "NCERT"
}


const Profile = () => {
    const accessToken = useSelector((state) => state.auth.userData.accessToken);
    const clientCode = useSelector((state) => state.auth.userData.user.clientCode);

    // const [user, setUser] = useState();

    const userType = useSelector((state) => state.auth?.userType);
    const userData = useSelector((state) => state.auth?.userData?.user);

    const [formData, setFormData] = useState();

    const [profileTrigger, setProfileTrigger] = useState(false);
    const [openBasicDetails, setOpenBasicDetails] = useState(false);
    const [openContactDetails, setOpenContactDetails] = useState(false);
    const [openAcademicDetails, setOpenAcademicDetails] = useState(false);

    const [profilePhotoUploaded, setProfilePhotoUploaded] = useState(false);

    useEffect(() => {
        setFormData(user);
    }, [openBasicDetails, openContactDetails, openAcademicDetails, profileTrigger, user])


    const handleImageDataChange = (data) => {
        setFormData({
            ...formData,
            profilePhoto: data,
        });
    }

    useEffect(() => {
        if (profilePhotoUploaded) {
            handleEdit();
        }
    }, [profilePhotoUploaded]);

    const handleEdit = async () => {

    }


    const handleImageChange = async (e) => {
        const file = e.target.files[0];

        if (file) {
            try {
                const formData = new FormData();
                formData.append('file', file);
                const uploadRes = await publicFileUpload(formData, accessToken, clientCode);
                if (uploadRes) {
                    handleImageDataChange(uploadRes?.data?.url);
                    setProfilePhotoUploaded(true);
                }

            } catch (error) {
                const errMsg =
                    error?.response?.data?.notify?.message ||
                    "An error occurred. Please try again.";
                toast.error(errMsg, 5000);
            }
        }
    }

    return (
        <Box>
            <AcademicModalHoc openNewInterviewModal={openBasicDetails} setOpenNewInterviewModal={setOpenBasicDetails} component={<ProfileBasicDetails formData={formData} setFormData={setFormData} handleEdit={handleEdit} />} />
            <AcademicModalHoc openNewInterviewModal={openContactDetails} setOpenNewInterviewModal={setOpenContactDetails} component={<ProfileContactDetails formData={formData} setFormData={setFormData} handleEdit={handleEdit} />} />
            <AcademicModalHoc openNewInterviewModal={openAcademicDetails} setOpenNewInterviewModal={setOpenAcademicDetails} component={<ProfileAcademicDetails formData={formData} setFormData={setFormData} handleEdit={handleEdit} />} />
            <div className='topBox'>
                {user?.profilePhoto ?
                    <div className='logoImgBox'>
                        <Label htmlFor='input'><img src={user?.profilePhoto} className='logoImg' /></Label>
                        <input
                            id='input'
                            type="file"
                            onChange={handleImageChange}
                            accept=".png, .jpg, .jpeg"
                            style={{ display: 'none' }}
                        />
                    </div> :
                    <div className='profileImgBox'>
                        <Label htmlFor='input'><img src={cameraIcon} className='cameraImg' /></Label>
                        <input
                            id='input'
                            type="file"
                            onChange={handleImageChange}
                            accept=".png, .jpg, .jpeg"
                            style={{ display: 'none' }}
                        />
                    </div>}
                <div className='middleBox'>
                    <span className='name'>{userData?.firstName}</span>
                    <div className='infoBox'>
                        <a href={user?.portfolio} target="_blank"><img src={website} className='socialIcon' />{user?.portfolio}</a>
                    </div>
                </div>
                <span className='editBtn' onClick={() => setOpenBasicDetails(true)}><img src={editIcon} /></span>
            </div>
            <hr className='hr'/>

            <div className='contactMainBox'>
                <span className='mainTitle'>
                    <span>Contact Details</span>
                    <span className='editBtn' onClick={() => setOpenContactDetails(true)}><img src={editIcon} /></span>
                </span>

                <div className='contactBox'>
                    <div className='childBox'>
                        <span className='text'><span className='boldText'>Contact:</span> {user?.spocContact}</span>
                        <span className='text'><span className='boldText'>Contact Name:</span> {userData?.firstName}</span>
                    </div>
                    <div className='childBox'>
                        <span className='text'><span className='boldText'>Email:</span> {userData?.email}</span>
                        <span className='text'><span className='boldText'>Address:</span> {user?.address} {user?.city}</span>
                    </div>
                </div>

            </div>
            <hr className='hr'/>
            <div className='contactMainBox'>
                <span className='mainTitle'>
                    <span>Academic Details</span>
                    <span className='editBtn' onClick={() => setOpenAcademicDetails(true)}><img src={editIcon} /></span>
                </span>

                <div className='contactBox'>
                    <div className='childBox'>
                        <span className='text'><span className='boldText'>UserName:</span>{userData?.username}</span>
                        <span className='text'><span className='boldText'>User Type:</span>{userData?.userType}</span>
                    </div>
                    <div className='childBox'>
                        <span className='text'><span className='boldText'>Enrolled Courses:</span> <span className='enrolledCourse'>{user?.enrolledCourses?.map((course, i) => (<span>{course}, </span>))}</span></span>
                        <span className='text'><span className='boldText'>Course Progress:</span> {user?.courseProgress}</span>
                    </div>
                    <div className='childBox'>
                        <span className='text'><span className='boldText'>School/University Name:</span> {user?.school}</span>
                        <span className='text'><span className='boldText'>Standard/Class:</span> {user?.std}</span>
                    </div>
                    <div className='childBox'>
                        <span className='text'><span className='boldText'>Board of Study:</span> {user?.boardOfStudy}</span>
                        <span className='text'><span className='boldText'>Study Pattern:</span>{user?.studyPattern}</span>
                    </div>
                </div>
            </div>
        </Box>
    )
}

export default Profile


const Box = styled.div`
width: 94%;
padding: 2rem 2rem;
margin: 1rem auto 2rem auto;
min-height: 100vh;
display: flex;
flex-direction: column;
gap: 0.5rem;
align-items: center;
background-color: var(--white);
justify-content: start;
border-radius: 1rem;
box-sizing: border-box;
box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.20);


.hr {
width:100%;
height: 0.1rem;
background-color: var(--academicLavendar);
border: none;
border: radius: 0.1rem;
}

.topBox {
    display: flex;
    width: 100%;
    gap: 1rem;
    justify-content: space-between;
    align-items: center;
    // border: 0.07rem solid lightgrey;
    padding: 1rem 1rem;
    border-radius: 0.5rem;
    background-color: #FEFFFE;


    
    .profileImgBox {
        width: 5rem;
        height: 5rem;
        border-radius: 50%;
        border: 0.1rem solid lightgrey; 
        display: flex;
        align-items: center;
        justify-content: center;

        .cameraImg {
            width: 1.5rem;
        }
    }

    .logoImgBox {
        width: 5rem;
        height: 5rem;
        border-radius: 50%;
        border: 0.1rem solid lightgrey; 
        display: flex;
        align-items: center;
        justify-content: center;

        .logoImg {
                width: 5rem;
                height: 5rem;
                border-radius: 50%;      
        }
    }

    .middleBox {
        width: 65%;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .name {
            font-size: 1.5rem;
            font-weight: 600;
            line-height: 2rem;
        }


        .infoBox {
            display: flex;
            gap: 1.5rem;

                span {
                    display: flex;
                    align-items: center;
                    gap: 0.4rem;
                }

                img {
                    width: 1rem;
                }

                a {
                    display: flex;
                    align-items: center;
                    gap: 0.4rem;
                    font-size: 0.8rem;
                    text-decoration: none;
                    color: var(--color);
                }

                .socialIcon {
                    width: 1.1rem;
                }
        }
    }

    .profileImg {
        width: 5rem;
        height: 5rem;
        border-radius: 50%;
        border: 0.1rem solid var(--academicLavendar);       
    }

    .editBtn {
        width: 1.3rem;
        cursor: pointer;
        padding-right: 1rem;

        img {
            width: 100%;
        }
    }
}


.textEditor {
    height: calc(100% - 6rem);
}

.descBox {
    min-height: 10rem;
}


.contactMainBox {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 1rem;
    justify-content: start;
    // border: 0.07rem solid lightgrey;
    padding: 1rem 1rem;
    border-radius: 0.5rem;
    background-color: #FEFFFE;


    .mainTitle {
        font-size: 1.2rem;
        font-weight: 600;
        display: flex;
        width: 100%;
        justify-content: space-between;


        .editBtn {
            width: 1rem;
            cursor: pointer;
            padding-right: 1rem;
    
            img {
                width: 100%;
            }
        }
    }

    .contactBox {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .childBox {
            width: 50%;
            display: flex;
            flex-direction: column;
            gap: 1rem;
        }

        .text {
            font-size: 0.75rem;
            font-weight: 400;
            display: flex;
            flex-direction: column;
            gap: 0.3rem;
        }

        .boldText {
            font-size: 0.9rem;
            font-weight: 600;
        }

        .enrolledCourse {
        dispaly: flex;
        flex-direction: row;
        gap: 0.5rem;
        width: 100%;

        
        }

    }
}
`

const Label = styled.label`
  font-weight: 600;
  margin: 0.7rem 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;


  img {
    width: 2rem;
  }
  
  span {
    color: var(--color);
    
  }
`;

