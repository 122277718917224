import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { toast } from "react-toastify";
import { getSavedJobs } from '../../../../functions/api/jobApplication/getSavedJobs';
import ConfigurableModal from '../seekerCommonComponents/ConfigurableModal';
import JobApplicationModal from '../seekerCommonComponents/JobApplicationModal';
import { useSelector } from 'react-redux';
import { getJdByClientCode } from '../../../../functions/api/employers/getJdByClientCode';
import { Pagination, PaginationSizeFilter } from '../../../commonComponents/Pagination';
import CommonDrawer from '../../../commonComponents/CommonDrawer';
import { dateConvert } from '../../../../utils/globalFunctions';
import DOMPurify from 'dompurify';
import experienceIcon from '../../../../assets/icons/JdDetails/experienceIcon.png'
import ctcIcon from '../../../../assets/icons/JdDetails/ctcIcon.png'
import locationIcon from '../../../../assets/icons/JdDetails/locationIcon.png'
import descriptionIcon from '../../../../assets/icons/JdDetails/active-job-desc.png'
import JdsDetails from '../../EmployerDashboard/sidebarPages/JdsDetails';
import view from '../../../../assets/icons/visible.png'
import saved from '../../../../assets/icons/saved.png'
import { removeSavedJob } from '../../../../functions/api/jobApplication/removeSavedJob';

function Row(props) {
  const { row, index, setSaveTrigger, page, size } = props;
  const accessToken = useSelector(state => state.auth.userData?.accessToken);

  const [openBasic, setOpenBasic] = useState(false);
  const [state, setState] = React.useState({
    right: false,
  });

  const [companyDetails, setCompanyDetails] = useState({});
  const [totalJdData, setTotalJdData] = useState();

  const handleClose = () => {
    setOpenBasic(false);
  }

  useEffect(() => {
    const getJd = async () => {
      try {
        const res = await getJdByClientCode(row?.jdId, accessToken, row?.clientCode);
        if (res) {
          setTotalJdData(res?.data)
        }
      }
      catch (error) {
        const errMsg =
          error?.response?.data?.notify?.message ||
          "An error occurred. Please try again.";
        toast.error(errMsg, 8000);
      }
    }
    getJd();
  }, [page, size, row?.jdId, row?.clientCode])

  const handleRemoveSaved = async (id) => {
    try {
      const res = await removeSavedJob(id, accessToken);
      if (res) {
        toast.success("Removed Successfully", 5000);
        setSaveTrigger(prev => !prev);
      }
    } catch (error) {
      const errMsg =
        error?.response?.data?.notify?.message ||
        "An error occurred. Please try again.";
      toast.error(errMsg, 8000);
    }
  }

  const sanitizedDesc = DOMPurify.sanitize(totalJdData?.description);
  const plainTextDescription = sanitizedDesc.replace(/<[^>]+>/g, '');

  // Open and close drawer for job details view.
  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  return (
    <CardMainBox>
      <ConfigurableModal open={openBasic} setOpen={setOpenBasic} component={<JobApplicationModal jdId={row?.jdId} empClientCode={row?.clientCode} handleClose={handleClose} />} style={{ width: '50%', height: '70%' }} />
      <Card>
        <div className='box1'>
          <div className='left'>
            <img src={row?.companyLogo} className='logo' />
            <div className='textBox'>
              <span className='title'>{totalJdData?.title}</span>
              <span className='subTitle'>{row?.companyName}</span>
            </div>
          </div>
          <div className='right'>
            <div className='box'>
              <CommonDrawer toggleDrawer={toggleDrawer} state={state} component={<JdsDetails Jds={totalJdData} companyName={row?.companyName} row={row} />} />
              <img src={view} onClick={toggleDrawer('right', true)} className='icon' />
            </div>
          </div>
        </div>

        <div className='box2'>
          {totalJdData?.exp && <span className='text'><img src={experienceIcon} /> {totalJdData?.exp} Yrs</span>} {totalJdData?.exp && <>|</>}
          {totalJdData?.ctc && <span className='text'><img src={ctcIcon} /> {totalJdData?.ctc} Lacs PA</span>} {totalJdData?.exp && <>|</>}
          {totalJdData?.location && <span className='text'><img src={locationIcon} /> {totalJdData?.location}</span>}
        </div>

        <div className='box3'>
          <span className='text'><img src={descriptionIcon} /> {plainTextDescription?.length > 125 ? plainTextDescription?.substring(0, 125) + '...' : plainTextDescription}</span>
        </div>

        <div className='box4'>
          {totalJdData?.skills?.split(',')?.map((skill, index) => (
            <span className='text' key={index}>{skill}</span>
          ))}
        </div>

        <div className='box5'>
          <span className='left'>{(row?.createdAt) && dateConvert(row?.createdAt)}</span>
          <span className='right'>
            <span className='boldText' onClick={() => handleRemoveSaved(row?.id)}><img className='icon' src={saved} /> Saved</span>
            <button onClick={() => setOpenBasic(true)} className="btn">Apply</button>
          </span>
        </div>
      </Card>
    </CardMainBox>
  );
}


const SavedJobsList = () => {
  const [jobListings, setJobListings] = useState([]);
  const accessToken = useSelector(state => state.auth.userData?.accessToken);
  const clientCode = useSelector(state => state.auth.userData?.user?.clientCode);

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [saveTrigger, setSaveTrigger] = useState(false);


  const handleSizeChange = (event) => {
    setSize(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handlePageChange = (change) => {
    if (change && page < Math.ceil(+total / +size)) {
      setPage((prev) => prev + 1);
    } else if (!change && page > 1) {
      setPage((prev) => prev - 1);
    }
  };


  useEffect(() => {
    try {
      const getData = async () => {
        const res = await getSavedJobs(accessToken, clientCode, page, size);
        if (res) {
          setJobListings(res?.data?.data);
          setTotal(res?.data?.total)
        }
      }

      getData();
    }
    catch (error) {
      const errMsg =
        error?.response?.data?.notify?.message ||
        "An error occurred. Please try again.";
      toast.error(errMsg, 8000);
    }
  }, [page, size, saveTrigger])


  return (
    <>
      {total === 0 ? <NotFoundTitle>No Saved Jobs found.</NotFoundTitle> :
        <Container1>
          <StyledBox>
            {jobListings?.map((row, index) => (
              <Row key={row?.jobId} row={row} index={index} setSaveTrigger={setSaveTrigger} page={page} size={size} />
            ))}
          </StyledBox>
          <div className="paginationBox">
            <PaginationSizeFilter
              size={size}
              handleSizeChange={handleSizeChange}
            />
            <Pagination
              total={total}
              size={size}
              page={page}
              handlePageChange={handlePageChange}
              setPage={setPage}
            />
          </div>
        </Container1>}
    </>
  );
};

export default SavedJobsList;



const StyledBox = styled.div`
  display: flex;
  margin-top: 0.5rem;
  margin-bottom: 2.5rem;
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  
`;



const Container1 = styled.div`
  width: 95%;
  margin: 1rem auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 0rem;


  .paginationBox {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin: 0.5rem 0 1.5rem 0;
  }
`;


const CardMainBox = styled.div`
width: 100%;
border: 0.05rem solid lightgrey;
border-radius: 1rem;
background-color: var(--white);
color: var(--color);
font-family: var(--font);




`

const Card = styled.div`
padding: 1rem 1.75rem;
box-sizing: border-box;
display: flex;
flex-direction: column;
gap: 0.5rem;


.box1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .left {
        display: flex;
        gap: 1rem;
        padding-bottom: 0.75rem;
        .logo {
            width: 2.5rem;
            height: 2.5rem;
            border: 0.025rem solid lightgrey;
            border-radius: 0.25rem;
        }
    
        .textBox {
            display: flex;
            flex-direction: column;
            gap: 0.3rem;
    
            .title {
                font-size: 1rem;
                font-weight: 700;
            }
            .subTitle {
                font-size: 0.9rem;
                font-weight: 600;
            }
        }
    }

    .right {
        .box{
            display: flex;
            gap: 0.5rem; 
            justify-content: center;
            align-items: center;

            .icon {
                width: 0.8rem;
                height: 0.8rem;
                cursor: pointer;
                border: 0.08rem solid grey;
                padding: 0.3rem;
                border-radius: 0.3rem;
            }
        }
    }
    
}

.box2 {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;

    .text {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
        align-items: center;
        font-size: 0.9rem;
        font-weight: 500;

        img {
            width: 1rem;
            height: 1rem;
        }
    }
}


.box3 {

    .text {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
        align-items: start;
        font-size: 0.9rem;
        font-weight: 500;
        line-height: 1.3rem;

        img {
            width: 1rem;
            height: 1rem;
            padding-top: 0.1rem;
        }
    }
}

.box4 {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    padding-top: 0.5rem;

    .text{
        font-size: 0.75rem;
        font-weight: 400;
        border: 0.025rem solid lightgrey;
        border-radius: 0.25rem;
        padding: 0.15rem 0.25rem;
    }
}

.box5 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 0.9rem;

    .left {
        font-weight: 500;
    }

    .right {
        display: flex;
        flex-direction: row;
        gap: 1rem;

        .btn {
            background-color: var(--lightOrange);
            padding: 0.5rem 0.8rem;
            border: none;
            color: var(--white);
            font-size: 0.9rem;
            font-weight: 600;
            border-radius: 0.5rem;
            cursor: pointer;
            text-decoration: none;
            font-family: var(--font);
        }

        .boldText {
          font-size: 0.9rem;
          font-weight: 600;
          display: flex;
          align-items: center;
          gap: 0.5rem;
          cursor: pointer;
        }

        .icon {
          width: 0.8rem;
          height: 0.8rem;
          cursor: pointer;
          border: 0.08rem solid grey;
          padding: 0.3rem;
          border-radius: 0.3rem;
      }
        
    }
}

`

const NotFoundTitle = styled.span`
font-size: 1.25rem;
font-weight: 600;
padding-top: 3rem;

`