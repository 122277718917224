import axios from "axios";

export const getTokenDetail = async (accessToken, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
        },
    };

    try {
        const response = await axios.get(` ${process.env.REACT_APP_API_URL}/api/interviews/tokens/${token}`, config);
        return response?.data;
    } catch (error) {
        throw error;
    }
};
