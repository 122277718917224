import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { IconButton } from "@mui/material";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import MyDrawer from "../../LandingPage/Drawer";
import logo from "../../../assets/Academic/logo.png";
import { useSelector } from "react-redux";
import { useJwt } from "react-jwt";
import { useDispatch } from "react-redux";
import { setCurrentItem } from "../../../slices/configSlice";

const AcademicLandingHeader = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [dashboardLink, setDashboardLink] = useState("");
    const accessToken = useSelector(state => state.auth.userData?.accessToken);
    const clientCode = useSelector(state => state.auth.userData?.user?.clientCode);

    const role = useJwt(accessToken);
    const [openDrawer, setOpenDrawer] = useState(false);

    useEffect(() => {
        const currentUser = role?.decodedToken?.type;
        if (currentUser == "ADMIN") {
            setDashboardLink("/dashboard/academic");
        }
        else if (currentUser == "STUDENT") {
            setDashboardLink("/dashboard/academic");
        }
        else {
            setDashboardLink("/dashboard/academic");
        }
    }, [role])

    const navigateToDashboard = () => {
        dispatch(setCurrentItem('dashboard'));
        navigate(dashboardLink);
    };


    return (
        <StyledDiv>
            <div id="left">
                <img src={logo} onClick={() => navigate("/academic")} />
            </div>


            <div id="right">
                {accessToken ? (
                    <span className="link" onClick={navigateToDashboard}>
                        {" "}
                        <span id="sign-in" style={{ paddingRight: '0.5rem' }}>Go to Dashboard</span>
                    </span>
                ) : (
                    <>
                        <Link to="/academic/login" className="link">
                            <span id="sign-in">Login</span>
                        </Link>
                        <span>|</span>
                        <Link to="/academic/signup" className="link">
                            <span id="free">Register</span>
                        </Link>
                    </>
                )}

                {/* <Link to="/demo" className="link">
                    <span className="demo">Schedule Demo</span>
                </Link> */}
            </div>
            <div id="drawer">
                <IconButton onClick={() => setOpenDrawer(true)}>
                    <MenuRoundedIcon className="Icon" />
                </IconButton>
                <MyDrawer openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} />
            </div>
        </StyledDiv>
    );
};

export default AcademicLandingHeader;

const StyledDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--color);
  width: 90%;
  padding: 0% 5%;
  height: 4rem;
  position: fixed;
  z-index: 2000;
  background: rgb(239,236,247);
  background: linear-gradient(90deg, rgba(239,236,247,1) 0%, rgba(239,236,247,1) 35%, rgba(168,162,226,1) 100%);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);

  #left > img {
    height: 3.5rem;
    margin-left: -5%;
    cursor: pointer;
  }

  #middle {
    display: flex;
    gap: 1rem;
  }

  h1 {
    margin: 0;
    color: #add8e6;
  }

  li {
    list-style-type: none;
    height: 5rem;
    display: flex;
    align-items: center;
  }

  #middle {
    font-size: 0.85rem;

    li {
      cursor: pointer;

      .contactLink {
        text-decoration: none;
        color: var(--color);
      }
    }

    li:hover {
      font-weight: 500;
    }

    @media (max-width: 900px) {
      display: none;
    }
  }

  #right {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    #sign-in {
      color: var(--white);
      font-weight: 600;
      font-size: 0.9rem;
      cursor: pointer;
    }

    #free {
        color: var(--white);
        font-weight: 600;
        font-size: 0.9rem;
        cursor: pointer;

    }

    span {
        color: var(--white);
    }
  }

  .demo {
    background: #515290;
    padding: 0.6rem 0.6rem;
    color: var(--white);
    font-weight: 600;
    font-size: 0.85rem;
    cursor: pointer;
    border-radius: 0.5rem;
  }

  .demo:hover {
    background: linear-gradient(to bottom, #8ACCDC, #515290);
  }
  

  .link {
    text-decoration: none;
    color: var(--white);
  }


  .Icon {
    color: var(--color);
  }

  // Menu dropdown Boxes

  .menuBox1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 2rem 2rem 1rem 1rem;
    min-width: 12rem;
  }

  .menuBox2 {
    display: flex;
    flex-direction: column;
    width: 25rem;
    background: linear-gradient(to top left, #fafafa, var(--lightOrange));
    align-items: center;
    min-height: 5rem;
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
    padding: 2rem 2rem;
    justify-content: center;
    gap: 1rem;
  }

  .menuBox2Title {
    text-align: center;
    font-size: 1.1rem;
    font-weight: 600;
    color: var(--color);
  }

  .menuBox2Text {
    text-align: center;
    font-size: 0.9rem;
    font-weight: 500;
    color: var(--color);
  }


  // Products Menu

  .dropdown-toggle1{
    position: relative;
  }
  

  .dropdown1:hover .dropdown-toggle1{
    background-image: linear-gradient(to right, var(--grey), var(--lightOrange));
    background-size: 100% 3px;
    background-repeat: no-repeat;
    background-position: 0 70%;
  }


  .dropdown-menu1 {
    display: none;
    position: absolute;
    top: 100%;
    // left: 50%;
    // transform: translateX(-50%);
    background-color: var(--white);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
    border-radius: 1rem;
  }

  

  
  .dropdown-menu1 a {
    display: inline-block;
    padding: 0.5rem 0;
    margin: 0 0.5rem;
    text-decoration: none;
    color: var(--grey);
    font-size: 0.9rem;
    font-weight: 500;
  }

  .dropdown-menu1 a:hover {
    background-image: linear-gradient(to right, var(--grey), var(--lightOrange));
    background-size: 100% 2.4px;
    background-repeat: no-repeat;
    background-position: 0 90%;
  }

  .nav-item1:hover .dropdown-menu1 {
    display: flex;
    align-items: start;
  }


  // Services Menu

  .dropdown-toggle2 {
    position: relative;
  }
  

  .dropdown2:hover .dropdown-toggle2{
    background-image: linear-gradient(to right, var(--grey), var(--lightOrange));
    background-size: 100% 3px;
    background-repeat: no-repeat;
    background-position: 0 70%;
  }


  .dropdown-menu2 {
    display: none;
    position: absolute;
    top: 100%;
    // left: 50%;
    // transform: translateX(-50%);
    background-color: var(--white);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
    border-radius: 1rem;
  }
  
  .dropdown-menu2 a {
    display: inline-block;
    padding: 0.5rem 0;
    margin: 0 0.5rem;
    text-decoration: none;
    color: var(--grey);
    font-size: 0.9rem;
    font-weight: 500;
  }

  .dropdown-menu2 a:hover {
    background-image: linear-gradient(to right, var(--grey), var(--lightOrange));
    background-size: 100% 2.4px;
    background-repeat: no-repeat;
    background-position: 0 90%;
  }

  .nav-item2:hover .dropdown-menu2 {
    display: flex;
    align-items: start;
  }
  

  // Solutions Menu

  .dropdown-toggle3 {
    position: relative;
  }
  

  .dropdown3:hover .dropdown-toggle3{
    background-image: linear-gradient(to right, var(--grey), var(--lightOrange));
    background-size: 100% 3px;
    background-repeat: no-repeat;
    background-position: 0 70%;
  }


  .dropdown-menu3 {
    display: none;
    position: absolute;
    top: 100%;
    // left: 50%;
    // transform: translateX(-50%);
    background-color: var(--white);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
    border-radius: 1rem;
  }
  
  .dropdown-menu3 a {
    display: inline-block;
    padding: 0.5rem 0;
    margin: 0 0.5rem;
    text-decoration: none;
    color: var(--grey);
    font-size: 0.9rem;
    font-weight: 500;
  }

  .dropdown-menu3 a:hover {
    background-image: linear-gradient(to right, var(--grey), var(--lightOrange));
    background-size: 100% 2.4px;
    background-repeat: no-repeat;
    background-position: 0 90%;
  }

  .nav-item3:hover .dropdown-menu3 {
    display: flex;
    align-items: start;
  }




  #drawer {
    display: none;

    @media (max-width: 900px) {
      display: block;
    }
  }
`;
