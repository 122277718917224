import React, { useEffect, useRef, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import styled, { css } from 'styled-components';
import { getAllTrackers } from "../../../../functions/api/interview/getAllTrackers";
import { useSelector } from "react-redux";
import { addResumes, addSelectedJd, resetInviteStatus, resetSelectedJd, resetSelection, toggleSelected } from "../../../../slices/invitationSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import TableSearchBar from "../commonComponents/TableSearchBar";
import EmpCommonButton from "../commonComponents/EmpCommonButton";
import { Pagination, PaginationSizeFilter } from "../../../commonComponents/Pagination";
import { toast } from "react-toastify";
import filterIcon from '../../../../assets/icons/filter.png'


function Row(props) {
    const { row, rowsLength, index, handleSelectArray, updateTrigger, isCandidateSelected, handleSelectChange } = props;

    const dropdownRef = useRef(null);
    const [openDropdownIndex, setOpenDropdownIndex] = useState(-1);
    const selected = useSelector((state) => state?.invite?.selectedRows);

    const openDropdown = (index) => {
        setOpenDropdownIndex(index);
    };

    const closeAllDropdowns = () => {
        setOpenDropdownIndex(-1);
    };


    useEffect(() => {
        const handleDocumentClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                closeAllDropdowns();
            }
        };

        document.addEventListener('mousedown', handleDocumentClick);

        return () => {
            document.removeEventListener('mousedown', handleDocumentClick);
        };
    }, []);

    return (
        <React.Fragment>
            <TableRow
                sx={{ "& > *": { borderBottom: "unset" } }} className={`${index % 2 == 1 ? 'colored' : ''}`}>
                <TableCell align="center" className="tableCell">{row?.name}</TableCell>
                <TableCell align="center" className="tableCell">{row?.contact}</TableCell>
                <TableCell align="center" className="tableCell">{row?.jdId?.toUpperCase()}</TableCell>
                <TableCell align="center" className="tableCell">{row?.recruiter}</TableCell>
                <TableCell align="center" className="tableCell">{row?.hiringManager}</TableCell>
                <TableCell align="center" className="tableCell">{row?.round}</TableCell>
                <TableCell align="center" className="tableCell">{row?.interviewName}</TableCell>
                <TableCell align="center" className="tableCell">{row?.status}</TableCell>
                <TableCell component="th" scope="row" align="center" className="tableCell">
                    <BoxRow isLast={index >= rowsLength - 2}>
                        <input
                            type="checkbox"
                            className="three-dots"
                            checked={selected?.find(item => item?.id === row?.id)?.selected}
                            onChange={() => handleSelectChange(row)}
                            disabled={isCandidateSelected && !selected?.find(item => item?.id === row?.id)?.selected}
                        />
                    </BoxRow>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}


const InterviewFlowCompletedForInPerson = ({ handleClose }) => {
    const [tableRows, setTableRows] = useState([]);
    const [filterParams, setFilterParams] = useState('COMPLETED');
    const accessToken = useSelector(state => state.auth.userData?.accessToken);
    const clientCode = useSelector(state => state.auth.userData?.user?.clientCode);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [selectedArray, setSelectedArray] = useState([]);
    const [updateTrigger, setUpdateTrigger] = useState(false);

    const [searchValue, setSearchValue] = useState("");
    const [search, setSearch] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [allInterviews, setAllInterviews] = useState([]);
    const [page1, setPage1] = useState(1);
    const [size, setSize] = useState(10);
    const [total, setTotal] = useState(0);

    const filterRef = useRef(null);
    const [filterOpen, setFilterOpen] = useState(false);
    const [jdSet, setJdSet] = useState([]);
    const [filteredJdId, setFilteredJdId] = useState('');
    const [isCandidateSelected, setIsCandidateSelected] = useState(false);
    const selected = useSelector((state) => state?.invite?.selectedRows);


    useEffect(() => {
        const handleDocumentClick = (event) => {
            if (filterRef.current && !filterRef.current.contains(event.target)) {
                setFilterOpen(false);
            }
        };

        document.addEventListener('mousedown', handleDocumentClick);

        return () => {
            document.removeEventListener('mousedown', handleDocumentClick);
        };
    }, []);

    const handleSizeChange = (event) => {
        setSize(parseInt(event.target.value, 10));
        setPage1(1);
    };

    // running an effect to empty selectedArray whenever filter value changes
    useEffect(() => {
        setSelectedArray([]);
        dispatch(resetSelection());
        dispatch(resetSelectedJd());
        dispatch(resetInviteStatus());
    }, []);


    const handleSelectChange = (row) => {
        const isSelected = selected?.find(item => item?.id === row?.id);
        const isChecked = isSelected ? isSelected?.selected : false;

        dispatch(toggleSelected({ id: row?.id, value: !isChecked }));

        if (isChecked) {
            handleSelectArray(row?.id, false);
            setIsCandidateSelected(false);
        } else {
            handleSelectArray(row, true);
            setIsCandidateSelected(true);
        }
    }

    const handlePageChange = (change) => {
        if (change && page1 < Math.ceil(+total / +size)) {
            setPage1((prev) => prev + 1);
        } else if (!change && page1 > 1) {
            setPage1((prev) => prev - 1);
        }
    };

    const handleSelectArray = (id, action) => {
        if (action) {
            setSelectedArray((prev) => [...prev, id]);
        } else {
            setSelectedArray((prev) => prev?.filter((item) => item?.id !== id));
        }
    };


    useEffect(() => {
        const getAllData = async () => {
            try {
                const res = await getAllTrackers(accessToken, clientCode, 1, 1000000, "", "", "IN_PROCESS");
                let allTrackers = res?.data?.data;

                const uniqueSet = new Set(allTrackers.map(item => item.jdId));
                setJdSet([...uniqueSet]);
            } catch (error) {
                const errMsg =
                    error?.response?.data?.notify?.message ||
                    "An error occurred. Please try again.";
                toast.error(errMsg, 8000);
            }
        }

        getAllData()
    }, []);

    useEffect(() => {
        const getData = async () => {
            try {
                const res = await getAllTrackers(accessToken, clientCode, search ? 1 : page1, search ? 1000000 : size, filteredJdId === 'all' ? "" : filteredJdId, filterParams, 'IN_PROCESS');
                let array = res?.data?.data;
                if (array) {
                    const finalResult = array?.reduce((acc, it) => {
                        let current = it?.interview;
                        let jdInfoReq = {
                            name: current?.userName,
                            contact: current?.userContact,
                            jdId: it?.jdId,
                            recruiter: current?.recruiter,
                            hiringManager: current?.hiringManager,
                            round: it?.stage,
                            interviewName: current?.interviewName,
                            interviewId: current?.id,
                            status: current?.status,
                            resumeId: current?.resumeId,
                            id: it?.id,
                            email: it?.userEmail,
                            resumeId: current?.resumeId,
                        }

                        return [...acc, jdInfoReq];
                    }, []);
                    setTableRows(finalResult);
                    setAllInterviews(finalResult);
                    setTotal(res?.data?.total);
                }
            } catch (error) {
                const errMsg =
                    error?.response?.data?.notify?.message ||
                    "An error occurred. Please try again.";
                toast.error(errMsg, 8000);
            }
        }

        getData();
    }, [page1, size, filterParams, search, filteredJdId])


    useEffect(() => {
        if (searchValue?.trim()) {
            setSearch(true);
            setFilteredData(() =>
                allInterviews?.filter(
                    (item) =>
                        item?.name?.toLowerCase()?.includes(searchValue?.toLowerCase()) ||
                        item?.jdId?.toLowerCase()?.includes(searchValue?.toLowerCase())
                )
            );
        } else {
            setSearch(false);
        }
    }, [searchValue]);


    const handleMoveNextRound = () => {
        if(selectedArray?.length > 0){
        dispatch(addResumes([...selectedArray]));
        handleClose();
        } else {
            toast.warning("Select Resume First");
        }
    }

    return (
        <Content>
            <TableContainer component={Paper} className="tableBox">
                <div className="titleBox">
                    <span className="title">Select 1 Candidate from below Candidates List</span>
                </div>

                <SearchBarContainer>
                    <TableSearchBar value={searchValue} setValue={setSearchValue} />
                </SearchBarContainer>
                <Table aria-label="collapsible table">
                    <TableHead className="tableHead">
                        <TableRow>
                            <TableCell align="center" className="tableCell">Name</TableCell>
                            <TableCell align="center" className="tableCell">Contact</TableCell>
                            <TableCell align="center" className="tableCell flexCell">JD ID
                                <img src={filterIcon} className={`icon ${filterOpen == true ? "active" : ""}`}
                                    onClick={() => setFilterOpen(!filterOpen)} />
                                <div className={`filterBox ${filterOpen ? "open" : ""}`} ref={filterRef}>
                                    <div className="checkboxInput">
                                        <input
                                            type="radio"
                                            value='all'
                                            checked={filteredJdId === 'all'}
                                            onChange={() => setFilteredJdId('all')}
                                            id="all"
                                        />
                                        <label htmlFor="all">ALL</label>
                                    </div>
                                    {jdSet?.map((jd, i) => (
                                        <div className="checkboxInput">
                                            <input
                                                type="radio"
                                                value={jd}
                                                checked={filteredJdId === jd}
                                                onChange={() => setFilteredJdId(jd)}
                                                id={jd} />
                                            <label htmlFor={jd}>{jd?.toUpperCase()}</label>
                                        </div>
                                    ))}
                                </div>
                            </TableCell>
                            <TableCell align="center" className="tableCell">Recruiter</TableCell>
                            <TableCell align="center" className="tableCell">Hiring Manager</TableCell>
                            <TableCell align="center" className="tableCell">Current Round</TableCell>
                            <TableCell align="center" className="tableCell">Feeback</TableCell>
                            <TableCell align="center" className="tableCell">Status</TableCell>
                            <TableCell align="center" className="tableCell">Actions</TableCell>
                            {filterParams !== "COMPLETED" && <TableCell align="center" className="tableCell">Reschedule</TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody className="tableBody">
                        {search ?
                            filteredData?.map((row, index) => (
                                <Row key={row?.id} rowsLength={tableRows?.length} row={row} index={index} handleSelectArray={handleSelectArray} updateTrigger={updateTrigger} isCandidateSelected={isCandidateSelected} handleSelectChange={handleSelectChange}/>
                            ))
                            :
                            tableRows?.map((row, index) => (
                                <Row key={row?.id} rowsLength={tableRows?.length} row={row} index={index} handleSelectArray={handleSelectArray} updateTrigger={updateTrigger} isCandidateSelected={isCandidateSelected} handleSelectChange={handleSelectChange} />
                            ))
                        }
                    </TableBody>
                </Table>
                {!search && <div className="paginationBox">
                    <PaginationSizeFilter
                        size={size}
                        handleSizeChange={handleSizeChange}
                    />
                    <Pagination
                        total={total}
                        size={size}
                        page={page1}
                        handlePageChange={handlePageChange}
                        setPage={setPage1}
                    />
                </div>}
            </TableContainer>
            <div className="btnBox">
                {filterParams == 'COMPLETED' && <EmpCommonButton text='Done' func={() => handleMoveNextRound()} disabled={selectedArray?.length === 0} />}
            </div>
        </Content>
    )
}

export default InterviewFlowCompletedForInPerson



const SearchBarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 96%;
  margin: 0.5rem auto;
  background-color: var(--white);
  border-radius: 0.5rem;;
  padding: 0rem 1rem;
  gap: 1rem;

`

const Content = styled.div`
margin: 1rem 0% 2rem 0%;
width: 94%;
padding: 0 1.5%;
display: flex;
flex-direction: column;
align-items: center;


.colored {
  background-color: #ececec;
}

.paginationBox {
  display: flex;
  justify-content: end;
  gap: 2rem;
  margin: 1rem 3rem 1.5rem 0;
}

.tableBox {
  box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.20);
  border-radius: 0.5rem;
  padding-top: 1rem;
  margin-bottom: 1.5rem;



  .title {
    padding-left: 1.2rem;
    font-size: 0.9rem;
    font-weight: 600;
  }

  .titleBox {
  width: 99%; 
  padding: 0.5rem 0rem;;
  display: flex;
  align-items: center;
  justify-content: space-between;
  }

  &::-webkit-scrollbar {
    width: 0rem;
}


  &::-webkit-scrollbar-thumb {
    width: 0rem;
}

& {
  scrollbar-width: none;
} 
}

.MuiTableCell-root {
  border: none;
}

.MuiTableRow-root {
  border-bottom: none;
}

.selected {
  background-color: #d9fbf9;
  color: white;
}

.tableHead {
  background-color: #d1fff0;
  width: 100%;

  .icon {
    width: 1rem;
    height: 1rem;
    cursor: pointer;
  } 

  .flexCell {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    position: relative;
  }

  .filterBox {
    position: absolute;
    left: 75%;
    top: -1rem;
    display: none;
    background-color: var(--white);
    border: 0.075rem solid grey;
    border-radius: 0.3rem;
    height: 7rem;
    overflow-y: auto;
    padding: 1rem 0;
    z-index: 100;

    .checkboxInput {
      display: flex;
      padding: 0.2rem 1rem;
      font-size: 0.8rem;
      font-weight: 500;
      align-items: start;
      gap: 0.3rem;
    }

    input {
      height: 1rem;
      cursor: pointer;
    }

    label {
      cursor: pointer;
    }

    &::-webkit-scrollbar {
      width: 0.4rem;
  }
  
    &::-webkit-scrollbar-track {
      background: lightgrey;
      border-radius: 0.4rem;
  }
  
    &::-webkit-scrollbar-thumb {
      background: grey;
      width: 0.4rem;
      border-radius: 0.4rem;
  }
  
  & {
    scrollbar-width: none;
  } 
  }

  .filterBox.open {
    display: block;
  }


  .tableCell {
    font-size: 0.9rem;
    font-weight: 500;
    font-family: var(--font);
    color: var(--color);
  }
  
}

.tableBody {
  width: 100%;

  .tableCell {
    font-size: 0.8rem;
    font-weight: 400;
    font-family: var(--font);
    color: var(--color);
  }
}

.btnBox {
  display: flex;
  gap: 2rem;
  margin-bottom: 1rem;
}

`


const BoxRow = styled.div`
  position: relative;
  display: inline-block;

.three-dots {
  cursor: pointer;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: var(--white);
  box-shadow: 0 0.3rem 0.5rem 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
  right: 10%;
  border-radius: 0.5rem;
  font-size: 0.7rem;
  min-width: 13rem;
  justify-content: start;
  padding: 0.5rem 0.5rem;

  ${(props) =>
        props.isLast &&
        css`
      bottom: 1.4rem;
      right: 10%;
    `}s
}


.dropdown-content span {
  padding: 0.3rem 0.8rem;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--color);
  cursor: pointer;
}


.dropdown:hover .dropdown-content, .dropdown-content.open {
  display: block;
}

.threeDotIcon {
  width: 0.6rem;
  height: 0.6rem;
  cursor: pointer;
  border: 0.08rem solid grey;
  padding: 0.15rem;
  border-radius: 0.2rem;
}


.dropdownText {
  

  img {
  width: 0.6rem;
  height: 0.6rem;
  cursor: pointer;
  border: 0.08rem solid grey;
  padding: 0.15rem;
  border-radius: 0.2rem;
  }
}
`

const Button = styled.button`
background-color: var(--lightOrange);
color: ${(props) => (props.disabled ? '#e9e9e9' : 'var(--white)')};
padding: 0.5rem 0.8rem;
border: none;
font-size: 0.9rem;
font-weight: 600;
border-radius: 0.5rem;
cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    text-decoration: none;
    font-family: var(--font);

`