export const academicPracticeData = [
    {
        category: "Middle School",
        description: "Middle School students can use our AI-based portal to practice and prepare for exams with interactive mock tests, personalized learning plans, and detailed performance analytics. Our platform helps build strong foundational skills in core subjects.",
        courses: [
            {
                name: "6th Grade",
                subjects: [
                    {
                        name: "Math",
                        topics: ["Number System", "Algebra", "Geometry", "Mensuration", "Data Handling", "Ratio and Proportion", "Integers", "Fractions", "Decimals"]
                    },
                    {
                        name: "Science",
                        topics: ["Food and Nutrition", "Materials", "The World of the Living", "Motion and Measurement", "Light, Shadows and Reflections", "Electricity and Circuits", "Magnets", "Water", "Air Around Us", "Garbage In, Garbage Out"]
                    },
                    {
                        name: "English",
                        topics: ["Grammar", "Reading Comprehension", "Writing Skills", "Literature", "Vocabulary"]
                    },
                    {
                        name: "Social Studies",
                        topics: ["History", "Geography", "Civics"]
                    },
                    {
                        name: "Art",
                        topics: ["Drawing", "Painting", "Craft"]
                    },
                    {
                        name: "Physical Education",
                        topics: ["Fitness", "Sports", "Health Education"]
                    },
                    {
                        name: "Computer Science",
                        topics: ["Basics of Computers", "Introduction to Programming", "Internet and Email"]
                    }
                ]
            },
            {
                name: "7th Grade",
                subjects: [
                    {
                        name: "Math",
                        topics: ["Integers", "Fractions and Decimals", "Data Handling", "Simple Equations", "Lines and Angles", "The Triangle and its Properties", "Congruence of Triangles", "Comparing Quantities", "Rational Numbers", "Perimeter and Area"]
                    },
                    {
                        name: "Science",
                        topics: ["Nutrition in Plants and Animals", "Fibre to Fabric", "Acids, Bases and Salts", "Physical and Chemical Changes", "Weather, Climate and Adaptations", "Soil", "Respiration in Organisms", "Transportation in Animals and Plants", "Reproduction in Plants", "Motion and Time", "Electric Current and its Effects", "Light", "Water", "Forests", "Wastewater Story"]
                    },
                    {
                        name: "English",
                        topics: ["Grammar", "Reading Comprehension", "Writing Skills", "Literature", "Vocabulary"]
                    },
                    {
                        name: "History",
                        topics: ["Medieval India", "The Delhi Sultans", "The Mughal Empire", "Rulers and Buildings", "Town, Traders, and Craftspersons"]
                    },
                    {
                        name: "Geography",
                        topics: ["Environment", "Inside Our Earth", "Our Changing Earth", "Air", "Water", "Natural Vegetation and Wildlife", "Human Environment"]
                    },
                    {
                        name: "Art",
                        topics: ["Drawing", "Painting", "Craft"]
                    },
                    {
                        name: "Physical Education",
                        topics: ["Fitness", "Sports", "Health Education"]
                    },
                    {
                        name: "Computer Science",
                        topics: ["Introduction to Computers", "Programming with Scratch", "Internet Applications"]
                    }
                ]
            },
            {
                name: "8th Grade",
                subjects: [
                    {
                        name: "Math",
                        topics: ["Rational Numbers", "Linear Equations in One Variable", "Understanding Quadrilaterals", "Data Handling", "Squares and Square Roots", "Cubes and Cube Roots", "Comparing Quantities", "Algebraic Expressions and Identities", "Visualizing Solid Shapes", "Mensuration", "Exponents and Powers", "Direct and Inverse Proportions", "Factorization", "Introduction to Graphs", "Playing with Numbers"]
                    },
                    {
                        name: "Science",
                        topics: ["Crop Production and Management", "Microorganisms", "Synthetic Fibres and Plastics", "Materials: Metals and Non-Metals", "Coal and Petroleum", "Combustion and Flame", "Conservation of Plants and Animals", "Cell Structure and Functions", "Reproduction in Animals", "Reaching the Age of Adolescence", "Force and Pressure", "Friction", "Sound", "Chemical Effects of Electric Current", "Some Natural Phenomena", "Light", "Stars and the Solar System", "Pollution of Air and Water"]
                    },
                    {
                        name: "English",
                        topics: ["Grammar", "Reading Comprehension", "Writing Skills", "Literature", "Vocabulary"]
                    },
                    {
                        name: "Social Studies",
                        topics: ["History", "Geography", "Civics"]
                    },
                    {
                        name: "History",
                        topics: ["Modern India", "The British Raj", "Colonialism", "Nationalism", "Independence and Partition"]
                    },
                    {
                        name: "Geography",
                        topics: ["Resources", "Land, Soil, Water, Natural Vegetation and Wildlife Resources", "Mineral and Power Resources", "Agriculture", "Industries", "Human Resources"]
                    },
                    {
                        name: "Art",
                        topics: ["Drawing", "Painting", "Craft"]
                    },
                    {
                        name: "Physical Education",
                        topics: ["Fitness", "Sports", "Health Education"]
                    },
                    {
                        name: "Computer Science",
                        topics: ["Advanced Programming with Scratch", "HTML", "Cyber Safety"]
                    }
                ]
            }
        ]
    },
    {
        category: "High School",
        description: "High School students can leverage our AI-powered platform for comprehensive exam preparation, including adaptive practice tests, real-time feedback, and targeted revision strategies. Enhance your understanding of complex topics across various subjects.",
        courses: [
            {
                name: "9th Grade",
                subjects: [
                    {
                        name: "Math",
                        topics: ["Number Systems", "Polynomials", "Coordinate Geometry", "Linear Equations in Two Variables", "Introduction to Euclid's Geometry", "Lines and Angles", "Triangles", "Quadrilaterals", "Areas of Parallelograms and Triangles", "Circles", "Constructions", "Heron's Formula", "Surface Areas and Volumes", "Statistics", "Probability"]
                    },
                    {
                        name: "Physics",
                        topics: ["Motion", "Force and Laws of Motion", "Gravitation", "Work and Energy", "Sound"]
                    },
                    {
                        name: "Chemistry",
                        topics: ["Matter in Our Surroundings", "Is Matter Around Us Pure", "Atoms and Molecules", "Structure of the Atom"]
                    },
                    {
                        name: "Biology",
                        topics: ["The Fundamental Unit of Life", "Tissues", "Diversity in Living Organisms", "Why Do We Fall Ill", "Natural Resources", "Improvement in Food Resources"]
                    },
                    {
                        name: "English",
                        topics: ["Grammar", "Reading Comprehension", "Writing Skills", "Literature", "Vocabulary"]
                    },
                    {
                        name: "History",
                        topics: ["The French Revolution", "Socialism in Europe and the Russian Revolution", "Nazism and the Rise of Hitler", "Forest Society and Colonialism", "Pastoralists in the Modern World", "Peasants and Farmers"]
                    },
                    {
                        name: "Geography",
                        topics: ["India - Size and Location", "Physical Features of India", "Drainage", "Climate", "Natural Vegetation and Wildlife", "Population"]
                    },
                    {
                        name: "Economics",
                        topics: ["The Story of Village Palampur", "People as Resource", "Poverty as a Challenge", "Food Security in India"]
                    },
                    {
                        name: "Physical Education",
                        topics: ["Fitness", "Sports", "Health Education"]
                    },
                    {
                        name: "Computer Science",
                        topics: ["Basics of Information Technology", "Cyber Safety", "Societal Impacts of IT"]
                    }
                ]
            },
            {
                name: "10th Grade",
                subjects: [
                    {
                        name: "Math",
                        topics: ["Real Numbers", "Polynomials", "Pair of Linear Equations in Two Variables", "Quadratic Equations", "Arithmetic Progressions", "Triangles", "Coordinate Geometry", "Introduction to Trigonometry", "Some Applications of Trigonometry", "Circles", "Constructions", "Areas Related to Circles", "Surface Areas and Volumes", "Statistics", "Probability"]
                    },
                    {
                        name: "Physics",
                        topics: ["Electricity", "Magnetic Effects of Electric Current", "Light - Reflection and Refraction", "The Human Eye and the Colourful World", "Sources of Energy"]
                    },
                    {
                        name: "Chemistry",
                        topics: ["Chemical Reactions and Equations", "Acids, Bases and Salts", "Metals and Non-Metals", "Carbon and its Compounds", "Periodic Classification of Elements"]
                    },
                    {
                        name: "Biology",
                        topics: ["Life Processes", "Control and Coordination", "How do Organisms Reproduce", "Heredity and Evolution", "Our Environment", "Management of Natural Resources"]
                    },
                    {
                        name: "English",
                        topics: ["Grammar", "Reading Comprehension", "Writing Skills", "Literature", "Vocabulary"]
                    },
                    {
                        name: "History",
                        topics: ["The Rise of Nationalism in Europe", "Nationalism in India", "The Making of a Global World", "The Age of Industrialisation", "Print Culture and the Modern World"]
                    },
                    {
                        name: "Geography",
                        topics: ["Resources and Development", "Forest and Wildlife Resources", "Water Resources", "Agriculture", "Minerals and Energy Resources", "Manufacturing Industries", "Lifelines of National Economy"]
                    },
                    {
                        name: "Economics",
                        topics: ["Development", "Sectors of the Indian Economy", "Money and Credit", "Globalisation and the Indian Economy", "Consumer Rights"]
                    },
                    {
                        name: "Political Science",
                        topics: ["Power Sharing", "Federalism", "Democracy and Diversity", "Gender, Religion and Caste", "Popular Struggles and Movements", "Political Parties", "Outcomes of Democracy", "Challenges to Democracy"]
                    },
                    {
                        name: "Physical Education",
                        topics: ["Fitness", "Sports", "Health Education"]
                    },
                    {
                        name: "Computer Science",
                        topics: ["Programming with Python", "Cyber Safety", "Data Handling"]
                    }
                ]
            },
            {
                name: "11th Grade",
                subjects: [
                    {
                        name: "Math",
                        topics: ["Sets", "Relations and Functions", "Trigonometric Functions", "Principle of Mathematical Induction", "Complex Numbers and Quadratic Equations", "Linear Inequalities", "Permutations and Combinations", "Binomial Theorem", "Sequences and Series", "Straight Lines", "Conic Sections", "Introduction to Three-dimensional Geometry", "Limits and Derivatives", "Mathematical Reasoning", "Statistics", "Probability"]
                    },
                    {
                        name: "Physics",
                        topics: ["Physical World", "Units and Measurements", "Motion in a Straight Line", "Motion in a Plane", "Laws of Motion", "Work, Energy and Power", "System of Particles and Rotational Motion", "Gravitation", "Mechanical Properties of Solids", "Mechanical Properties of Fluids", "Thermal Properties of Matter", "Thermodynamics", "Kinetic Theory", "Oscillations", "Waves"]
                    },
                    {
                        name: "Chemistry",
                        topics: ["Some Basic Concepts of Chemistry", "Structure of Atom", "Classification of Elements and Periodicity in Properties", "Chemical Bonding and Molecular Structure", "States of Matter", "Thermodynamics", "Equilibrium", "Redox Reactions", "Hydrogen", "s-Block Element", "Some p-Block Elements", "Organic Chemistry", "Hydrocarbons", "Environmental Chemistry"]
                    },
                    {
                        name: "Biology",
                        topics: ["Diversity in the Living World", "Structural Organisation in Animals and Plants", "Cell Structure and Function", "Plant Physiology", "Human Physiology"]
                    },
                    {
                        name: "English",
                        topics: ["Grammar", "Reading Comprehension", "Writing Skills", "Literature", "Vocabulary"]
                    },
                    {
                        name: "Economics",
                        topics: ["Introduction to Microeconomics", "Consumer's Equilibrium and Demand", "Producer Behaviour and Supply", "Forms of Market and Price Determination", "Introduction to Macroeconomics", "National Income Accounting", "Money and Banking", "Government Budget and the Economy", "Balance of Payments"]
                    },
                    {
                        name: "Political Science",
                        topics: ["Indian Constitution at Work", "Political Theory"]
                    },
                    {
                        name: "History",
                        topics: ["Early Societies", "Empires", "Changing Traditions", "Paths to Modernisation"]
                    },
                    {
                        name: "Geography",
                        topics: ["Fundamentals of Physical Geography", "India - Physical Environment", "Practical Work in Geography"]
                    },
                    {
                        name: "Psychology",
                        topics: ["What is Psychology?", "Methods of Enquiry in Psychology", "The Bases of Human Behaviour", "Human Development", "Sensory, Attentional and Perceptual Processes", "Learning", "Human Memory", "Thinking", "Motivation and Emotion"]
                    },
                    {
                        name: "Sociology",
                        topics: ["Introducing Sociology", "Understanding Society"]
                    },
                    {
                        name: "Physical Education",
                        topics: ["Physical Fitness", "Sports", "Health Education"]
                    },
                    {
                        name: "Computer Science",
                        topics: ["Computer Fundamentals", "Programming Methodology", "Introduction to Python", "Data Handling", "Conditional and Looping Constructs", "Strings and Lists in Python", "Introduction to Web Technology"]
                    }
                ]
            },
            {
                name: "12th Grade",
                subjects: [
                    {
                        name: "Math",
                        topics: ["Relations and Functions", "Inverse Trigonometric Functions", "Matrices", "Determinants", "Continuity and Differentiability", "Applications of Derivatives", "Integrals", "Applications of Integrals", "Differential Equations", "Vector Algebra", "Three-dimensional Geometry", "Linear Programming", "Probability"]
                    },
                    {
                        name: "Physics",
                        topics: ["Electrostatics", "Current Electricity", "Magnetic Effects of Current and Magnetism", "Electromagnetic Induction and Alternating Currents", "Electromagnetic Waves", "Optics", "Dual Nature of Radiation and Matter", "Atoms and Nuclei", "Electronic Devices", "Communication Systems"]
                    },
                    {
                        name: "Chemistry",
                        topics: ["Solid State", "Solutions", "Electrochemistry", "Chemical Kinetics", "Surface Chemistry", "General Principles and Processes of Isolation of Elements", "p-Block Elements", "d- and f-Block Elements", "Coordination Compounds", "Haloalkanes and Haloarenes", "Alcohols, Phenols and Ethers", "Aldehydes, Ketones and Carboxylic Acids", "Organic Compounds containing Nitrogen", "Biomolecules", "Polymers", "Chemistry in Everyday Life"]
                    },
                    {
                        name: "Biology",
                        topics: ["Reproduction", "Genetics and Evolution", "Biology and Human Welfare", "Biotechnology and Its Applications", "Ecology and Environment"]
                    },
                    {
                        name: "English",
                        topics: ["Grammar", "Reading Comprehension", "Writing Skills", "Literature", "Vocabulary"]
                    },
                    {
                        name: "Economics",
                        topics: ["Introduction to Microeconomics", "Consumer's Equilibrium and Demand", "Producer Behaviour and Supply", "Forms of Market and Price Determination", "Introduction to Macroeconomics", "National Income Accounting", "Money and Banking", "Government Budget and the Economy", "Balance of Payments"]
                    },
                    {
                        name: "Political Science",
                        topics: ["Contemporary World Politics", "Politics in India since Independence"]
                    },
                    {
                        name: "History",
                        topics: ["Themes in Indian History - Part I", "Themes in Indian History - Part II", "Themes in Indian History - Part III"]
                    },
                    {
                        name: "Geography",
                        topics: ["Fundamentals of Human Geography", "India - People and Economy", "Practical Work in Geography"]
                    },
                    {
                        name: "Psychology",
                        topics: ["Variations in Psychological Attributes", "Self and Personality", "Meeting Life Challenges", "Psychological Disorders", "Therapeutic Approaches", "Attitude and Social Cognition", "Social Influence and Group Processes", "Psychology and Life", "Developing Psychological Skills"]
                    },
                    {
                        name: "Sociology",
                        topics: ["Indian Society", "Change and Development in India"]
                    },
                    {
                        name: "Physical Education",
                        topics: ["Physical Fitness", "Sports", "Health Education"]
                    },
                    {
                        name: "Computer Science",
                        topics: ["Review of Python", "Functions", "File Handling", "Stacks and Queues", "Database Management", "Networking", "Societal Impacts"]
                    }
                ]
            }
        ]
    },
    {
        category: "Competitive Exams",
        description: "Our portal offers specialized AI-based practice for various competitive exams, providing customized mock tests, performance tracking, and in-depth analysis to help students excel in exams like JEE, NEET, SAT, GRE, GMAT, and CAT.",
        courses: [
            {
                name: "JEE",
                subjects: [
                    {
                        name: "Math",
                        topics: ["Algebra", "Trigonometry", "Calculus", "Coordinate Geometry", "Vectors and 3D Geometry", "Probability", "Statistics"]
                    },
                    {
                        name: "Physics",
                        topics: ["Mechanics", "Thermodynamics", "Electrodynamics", "Optics", "Modern Physics"]
                    },
                    {
                        name: "Chemistry",
                        topics: ["Physical Chemistry", "Inorganic Chemistry", "Organic Chemistry"]
                    }
                ]
            },
            {
                name: "NEET",
                subjects: [
                    {
                        name: "Physics",
                        topics: ["Mechanics", "Thermodynamics", "Electrodynamics", "Optics", "Modern Physics"]
                    },
                    {
                        name: "Chemistry",
                        topics: ["Physical Chemistry", "Inorganic Chemistry", "Organic Chemistry"]
                    },
                    {
                        name: "Biology",
                        topics: ["Botany", "Zoology"]
                    }
                ]
            },
            {
                name: "SAT",
                subjects: [
                    {
                        name: "Math",
                        topics: ["Heart of Algebra", "Problem Solving and Data Analysis", "Passport to Advanced Math"]
                    },
                    {
                        name: "English",
                        topics: ["Reading Comprehension", "Writing and Language"]
                    },
                    {
                        name: "Critical Reading",
                        topics: ["Reading Comprehension", "Sentence Completion"]
                    },
                    {
                        name: "Writing",
                        topics: ["Essay Writing", "Grammar and Usage"]
                    },
                    {
                        name: "Essay",
                        topics: ["Essay Writing Techniques", "Practice Essays"]
                    }
                ]
            },
            {
                name: "GRE",
                subjects: [
                    {
                        name: "Verbal Reasoning",
                        topics: ["Reading Comprehension", "Text Completion", "Sentence Equivalence"]
                    },
                    {
                        name: "Quantitative Reasoning",
                        topics: ["Arithmetic", "Algebra", "Geometry", "Data Analysis"]
                    },
                    {
                        name: "Analytical Writing",
                        topics: ["Issue Task", "Argument Task"]
                    }
                ]
            },
            {
                name: "GMAT",
                subjects: [
                    {
                        name: "Analytical Writing",
                        topics: ["Analysis of an Argument"]
                    },
                    {
                        name: "Integrated Reasoning",
                        topics: ["Multi-Source Reasoning", "Table Analysis", "Graphics Interpretation", "Two-Part Analysis"]
                    },
                    {
                        name: "Quantitative",
                        topics: ["Problem Solving", "Data Sufficiency"]
                    },
                    {
                        name: "Verbal",
                        topics: ["Reading Comprehension", "Critical Reasoning", "Sentence Correction"]
                    }
                ]
            },
            {
                name: "CAT",
                subjects: [
                    {
                        name: "Verbal Ability and Reading Comprehension",
                        topics: ["Reading Comprehension", "Verbal Reasoning", "Grammar", "Sentence Correction", "Word Usage"]
                    },
                    {
                        name: "Data Interpretation and Logical Reasoning",
                        topics: ["Tables and Caselets", "Bar Graphs and Column Graphs", "Venn Diagrams", "Pie Chart", "Number and Letter Series", "Calendars", "Clocks", "Cubes", "Seating Arrangement", "Binary Logic", "Logical Matching", "Logical Connectives", "Syllogism"]
                    },
                    {
                        name: "Quantitative Ability",
                        topics: ["Number Systems", "Arithmetic", "Algebra", "Geometry", "Mensuration", "Probability", "Permutation and Combination", "Set Theory"]
                    }
                ]
            },
            {
                "name": "NDA",
                "subjects": [
                    {
                        "name": "Math",
                        "topics": ["Arithmetic", "Algebra", "Geometry", "Trigonometry", "Statistics"]
                    },
                    {
                        "name": "General Knowledge",
                        "topics": ["History", "Geography", "Economics", "General Science", "Current Affairs"]
                    },
                    {
                        "name": "English",
                        "topics": ["Grammar", "Vocabulary", "Reading Comprehension", "Writing Skills"]
                    },
                    {
                        "name": "Reasoning",
                        "topics": ["Verbal Reasoning", "Non-Verbal Reasoning"]
                    },
                    {
                        "name": "Current Affairs",
                        "topics": ["National and International News", "Sports", "Awards", "Books and Authors"]
                    }
                ]
            }
        ]
    },
    {
        category: "Government Exams",
        description: "Prepare for various government exams with our AI-driven platform offering extensive practice tests, real-time performance feedback, and detailed analytics. Ideal for exams like UPSC, SSC, Railway Exams, Banking Exams, State PSC, and Defence Exams.",
        courses: [
            {
                name: "UPSC",
                subjects: [
                    {
                        name: "General Studies",
                        topics: ["Indian Heritage and Culture", "History", "Geography", "Polity", "Economy", "Environment and Ecology", "General Science"]
                    },
                    {
                        name: "CSAT",
                        topics: ["Comprehension", "Interpersonal Skills", "Logical Reasoning and Analytical Ability", "Decision Making and Problem Solving", "General Mental Ability", "Basic Numeracy", "Data Interpretation"]
                    },
                    {
                        name: "Optional Subject",
                        topics: ["Various subjects depending on candidate's choice"]
                    },
                    {
                        name: "Essay",
                        topics: ["Essay Writing Techniques", "Practice Essays"]
                    },
                    {
                        name: "Ethics, Integrity, and Aptitude",
                        topics: ["Ethics and Human Interface", "Attitude", "Aptitude", "Emotional Intelligence", "Contributions of Moral Thinkers", "Public/Civil Service Values", "Probity in Governance"]
                    }
                ]
            },
            {
                name: "SSC",
                subjects: [
                    {
                        name: "General Knowledge",
                        topics: ["Indian History", "Geography", "Economics", "General Science", "Current Affairs"]
                    },
                    {
                        name: "Math",
                        topics: ["Arithmetic", "Algebra", "Geometry", "Trigonometry", "Statistics"]
                    },
                    {
                        name: "Reasoning",
                        topics: ["Verbal Reasoning", "Non-Verbal Reasoning"]
                    },
                    {
                        name: "English",
                        topics: ["Grammar", "Vocabulary", "Reading Comprehension", "Writing Skills"]
                    },
                    {
                        name: "Current Affairs",
                        topics: ["National and International News", "Sports", "Awards", "Books and Authors"]
                    }
                ]
            },
            {
                name: "Railway Exams",
                subjects: [
                    {
                        name: "General Awareness",
                        topics: ["Current Affairs", "History", "Geography", "Economics", "General Science"]
                    },
                    {
                        name: "Math",
                        topics: ["Arithmetic", "Algebra", "Geometry", "Trigonometry", "Statistics"]
                    },
                    {
                        name: "Reasoning",
                        topics: ["Verbal Reasoning", "Non-Verbal Reasoning"]
                    },
                    {
                        name: "Technical Subjects",
                        topics: ["Depending on specific railway job role"]
                    },
                    {
                        name: "Current Affairs",
                        topics: ["National and International News", "Sports", "Awards", "Books and Authors"]
                    }
                ]
            },
            {
                name: "Banking Exams",
                subjects: [
                    {
                        name: "Quantitative Aptitude",
                        topics: ["Number Systems", "Arithmetic", "Algebra", "Geometry", "Mensuration", "Data Interpretation"]
                    },
                    {
                        name: "Reasoning Ability",
                        topics: ["Verbal Reasoning", "Non-Verbal Reasoning"]
                    },
                    {
                        name: "English Language",
                        topics: ["Grammar", "Vocabulary", "Reading Comprehension", "Writing Skills"]
                    },
                    {
                        name: "General Awareness",
                        topics: ["Current Affairs", "Banking Awareness", "Economy"]
                    },
                    {
                        name: "Computer Knowledge",
                        topics: ["Basics of Computers", "Internet and Email", "MS Office"]
                    }
                ]
            },
            {
                name: "State PSC",
                subjects: [
                    {
                        name: "General Studies",
                        topics: ["History", "Geography", "Polity", "Economy", "Environment", "General Science"]
                    },
                    {
                        name: "CSAT",
                        topics: ["Comprehension", "Interpersonal Skills", "Logical Reasoning and Analytical Ability", "Decision Making and Problem Solving", "General Mental Ability", "Basic Numeracy", "Data Interpretation"]
                    },
                    {
                        name: "State-Specific Knowledge",
                        topics: ["State History", "State Geography", "State Economy", "State Polity"]
                    },
                    {
                        name: "Current Affairs",
                        topics: ["National and International News", "State-Specific News", "Sports", "Awards", "Books and Authors"]
                    },
                    {
                        name: "Optional Subject",
                        topics: ["Various subjects depending on candidate's choice"]
                    }
                ]
            },
            {
                name: "Defence Exams",
                subjects: [
                    {
                        name: "Math",
                        topics: ["Arithmetic", "Algebra", "Geometry", "Trigonometry", "Statistics"]
                    },
                    {
                        name: "General Knowledge",
                        topics: ["History", "Geography", "Economics", "General Science", "Current Affairs"]
                    },
                    {
                        name: "English",
                        topics: ["Grammar", "Vocabulary", "Reading Comprehension", "Writing Skills"]
                    },
                    {
                        name: "Reasoning",
                        topics: ["Verbal Reasoning", "Non-Verbal Reasoning"]
                    },
                    {
                        name: "Current Affairs",
                        topics: ["National and International News", "Sports", "Awards", "Books and Authors"]
                    }
                ]
            }
        ]
    }
];

export function getAllCategories() {
    return academicPracticeData.map(category => ({
        name: category.category,
        description: category.description
    }));
}

export function getCoursesByCategory(categoryName) {
    const category = academicPracticeData.find(cat => cat.category === categoryName);
    return category ? category.courses : [];
}

export function getAllCourses() {
    return academicPracticeData?.flatMap(category => category.courses.map(course => course.name));
}

export function getSubjectsByCourse(categoryName, courseName) {
    const category = academicPracticeData.find(cat => cat.category === categoryName);
    if (category) {
        const course = category.courses.find(crs => crs.name === courseName);
        return course ? course.subjects.map(subject => subject.name) : [];
    }
    return [];
}

export function getTopicsBySubject(categoryName, courseName, subjectName) {
    const category = academicPracticeData.find(cat => cat.category === categoryName);
    if (category) {
        const course = category.courses.find(crs => crs.name === courseName);
        if (course) {
            const subject = course.subjects.find(sub => sub.name === subjectName);
            return subject ? subject.topics : [];
        }
    }
    return [];
}

// Function to get all subjects of a course by courseName
export function getSubjectsByCourseName(courseName) {
    for (const category of academicPracticeData) {
        const course = category.courses.find(crs => crs.name === courseName);
        if (course) {
            return course.subjects.map(subject => subject.name);
        }
    }
    return []; 
}

export function getTopicsByCourseAndSubject(courseName, subjectName) {
    for (const category of academicPracticeData) {
        for (const course of category.courses) {
            if (course.name === courseName) {
                const subject = course.subjects.find(sub => sub.name === subjectName);
                return subject ? subject.topics : [];
            }
        }
    }
    return [];
}



export const academicLeaderboardDateRange = [
    { value: "today", text: "Today" },
    { value: "last7days", text: "Last 7 Days" },
    { value: "thisMonth", text: "This Month" },
    { value: "lastMonth", text: "Last Month" },
    { value: "last3Months", text: "Last 3 Months" },
    { value: "last6Months", text: "Last 6 Months" },
    { value: "thisYear", text: "This Year" },
    { value: "allTime", text: "All Time" }
];
