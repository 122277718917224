import React, { useState } from 'react'
import styled from 'styled-components'
import Label from '../../../../component/shared/Label'
import IconButton from '../../../../component/shared/IconButton'
import editIcon from '../../../../assets/icons/editBlack.png'
import deleteIcon from '../../../../assets/icons/delete.png'
import visibleIcon from '../../../../assets/icons/visible.png'
import publishIcon from '../../../../assets/icons/publish.png'
import CommonDrawer from '../../../../component/global/Drawer'
import { useSelector } from 'react-redux'
import CommonDialog from '../../../../component/global/CommonDialog'
import DeleteDialogContent from '../../../../component/global/DeleteDialogContent'
import { timeZoneConversion } from '../../../../utils/timeZoneConversation'
import TestDetailsPage from '../pages/TestDetailsPage'
import useTestActions from '../hooks/useTestActions'

const TestCard = ({ data, editTest, setTestTrigger, index }) => {
    const accessToken = useSelector(state => state.auth.userData?.accessToken);
    const clientCode = useSelector(state => state.auth.userData?.user?.clientCode);

    const {
        deleteOpen,
        handleDeleteClick,
        handleDeleteTest,
        setDeleteOpen
    } = useTestActions(accessToken, clientCode, setTestTrigger);

    const [state, setState] = useState({
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };


    return (
        <>
            <CommonDrawer toggleDrawer={toggleDrawer} state={state} component={<TestDetailsPage data={data} />} width='60vw' />
            <CommonDialog
                open={deleteOpen}
                handleClose={() => setDeleteOpen(false)}
                component={
                    <DeleteDialogContent
                        text="Test"
                        handleClose={() => setDeleteOpen(false)}
                        handleDelete={handleDeleteTest}
                    />
                }
            />

            <Card>
                <TopBox>
                    <span className="title">Test Title: {data?.name?.length > 150 ? data?.name?.slice(0, 150) + "..." : data?.name}</span>
                    <div className='iconBox'>
                        {!data?.published && <IconButton
                            icon={publishIcon}
                            width="1.5rem"
                            func={() => editTest(index, data?.id, data, data?.testQuestions, "review")}
                            isBorder={true}
                            altTitle="PublishButton" />}
                        <IconButton
                            icon={editIcon}
                            width="1.5rem"
                            func={() => editTest(index, data?.id, data, data?.testQuestions, "create")}
                            isBorder={true}
                            altTitle="EditButton" />
                        <IconButton
                            icon={deleteIcon}
                            width="1.5rem"
                            func={() => handleDeleteClick(data?.id)}
                            isBorder={true}
                            altTitle="DeleteButton" />
                        <IconButton
                            icon={visibleIcon}
                            width="1.5rem"
                            func={toggleDrawer('right', true)}
                            isBorder={true}
                            altTitle="viewDetailButton" />
                    </div>
                </TopBox>
                <BottomBox>
                    <div className='left'>
                        <Label
                            bgColor="transparent"
                            borderColor="grey"
                            borderRadius="1rem"
                            text={timeZoneConversion(data?.createdAt)}
                            title="Created At: "
                            isTitle={true}
                            fontType="bold" />
                        <Label
                            bgColor="transparent"
                            borderColor="grey"
                            borderRadius="1rem"
                            text={data?.role}
                            title="Role: "
                            isTitle={true}
                            fontType="bold" />
                        <Label
                            bgColor="transparent"
                            borderColor="grey"
                            borderRadius="1rem"
                            text={data?.totalScore}
                            title="Score: "
                            isTitle={true}
                            fontType="bold" />
                        <Label
                            bgColor="transparent"
                            borderColor="grey"
                            borderRadius="1rem"
                            text={data?.noOfQuestions}
                            title="No of Que: "
                            isTitle={true}
                            fontType="bold" />
                    </div>
                </BottomBox>
            </Card>
        </>
    )
}

export default TestCard

const Card = styled.div`
width: 100%;
margin: 0 auto;
padding: 0.75rem 1rem;
box-sizing: border-box;
border: 0.1rem solid lightgrey;
border-radius: 0.25rem;
display: flex;
flex-direction: column;
gap: 2rem;
background-color: var(--white);

`

const TopBox = styled.div`
width: 100%;
display: flex;
justify-content: space-between;
align-items: start;
gap: 2rem;

.title {
 font-size: 0.9rem;
 font-weight: 700;
}

.iconBox {
 display: flex;
 gap: 0.5rem;
 align-items: center;
}
`

const BottomBox = styled.div`
display: flex;
width: 100%;
justify-content: space-between;
align-items: center;
gap: 5%;

.left {
display: flex;
justify-content: space-between;
align-items: center;
gap: 2rem;
}

`

