import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import profileIcon from '../../assets/icons/profile.png'
import jobSearchIcon from '../../assets/icons/job-search.png'
import practiceInterviewIcon from '../../assets/icons/practice.png'
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import dashboardIcon from '../../assets/icons/dashboard.png'
import appliedJobIcon from '../../assets/icons/job-applied.png'
import interviewDashboardIcon from '../../assets/icons/interview-dashboard.png'
import callSupportIcon from '../../assets/icons/call-support.png'

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import titleIcon1 from '../../assets/icons/titleIcon1.png'
import titleIcon2 from '../../assets/icons/titleIcon2.2.png'
import titleIcon4 from '../../assets/icons/titleIcon2.4.png'
import titleIcon5 from '../../assets/icons/titleIcon2.5.png'
import titleIcon6 from '../../assets/icons/titleIcon2.6.png'
import { setCurrentItem } from '../../slices/configSlice';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

const Container = styled.div`
  width: 17rem;
  height: calc(98% - 2rem);
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 0.9rem;
  font-weight: 500;
  background-color: var(--white);
  position: fixed;
  left: 0;
  overflow-y: scroll;

  & {
    scrollbar-width: none;
  }  

  &::-webkit-scrollbar {
    width: 0rem;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }


  .menuTitle {
    font-size: 0.9rem;
    font-weight: 500;
    color: var(--color);
    padding-left: 0.5rem;
    display: flex;
    align-items: center;
    gap: 0.6rem;


    img {
      width: 1.3rem;
      height: 1.3rem;
    }
  }

  .menuTitle.selected {
    color: var(--academicLavendar);
  }

  .custom-accordion-root.MuiAccordion-root:before {
    display: none;
  }
  
  .custom-accordion-summary-root {
    border-bottom: none;
    margin-top: -8px;
  }

  .details {
    margin-bottom: -22px;
    margin-top: -10px;
  }

  .hrLine {
    width: 90%;
    border-top: 0.06rem solid #f6f6f6;
    border-radius: 0.2rem;
    margin: 0.8rem 0 0.8rem 0;
    align-self: center;
    box-shadow: 0 0.5px 0.5px rgba(0, 0, 0, 0.25);
  }
`;

const MenuItem = styled.div`
  padding-bottom: 1rem;
  margin-left: 1rem;
  font-size: 0.85rem;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  color: ${(props) => (props.isSelected ? 'var(--academicLavendar)' : 'var(--color)')};
  a{
    color: var(--color);
    text-decoration: none;
  }

  .icon {
    width: 1.2rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .arrowDown {
    font-size: 1rem;
    width: 1rem;
    height: 1rem;
    display: ${(props) => (props.isSelected ? 'none' : 'block')};
  }

  .arrowUp {
    font-size: 1rem;
    width: 1rem;
    height: 1rem;
    display: ${(props) => (props.isSelected ? 'block' : 'none')};
  }

  .openNew {
    font-size: 1rem;
    width: 1rem;
    height: 1rem;
  }

`;

const Arrow = styled.div`
    width: 0;
    height: 0;
    border-bottom: 1rem solid transparent;
    border-top: 1rem solid transparent;
    border-right: 1rem solid #f4f4f4;
    transition: transform 0.2s;
    position: absolute;
    right: 0px;

`;

const AcademicSidebar = () => {
    const [expanded, setExpanded] = useState(false);

    const currentItem = useSelector(state => state.config?.currentItem);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const interviewEndPoint = 'mock';

    const handleItemClick = (item) => {
        dispatch(setCurrentItem(item));
    };

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };


    return (
        <Container>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} style={{ width: '100%', boxShadow: 'none', margin: '0' }} classes={{ root: 'custom-accordion-root' }} >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    classes={{
                        root: 'custom-accordion-summary-root',
                        expanded: expanded === 'panel1' ? 'expanded' : ''
                    }}
                >
                    <span className={`menuTitle ${expanded === 'panel1' ? 'selected' : ''}`} onClick={() => handleItemClick('dashboard')}><img src={titleIcon1} /> Home</span>
                    {expanded === 'panel1' && <Arrow></Arrow>}
                </AccordionSummary>
                <AccordionDetails className='details'>
                    <MenuItem isSelected={currentItem === 'dashboard'} onClick={() => handleItemClick('dashboard')}>
                        <img src={dashboardIcon} className='icon' />
                        Dashboard
                    </MenuItem>
                </AccordionDetails>
            </Accordion>

            <span className='hrLine'></span>
            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} style={{ width: '100%', boxShadow: 'none', margin: '0' }} classes={{ root: 'custom-accordion-root' }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                    classes={{
                        root: 'custom-accordion-summary-root',
                        expanded: expanded === 'panel2' ? 'expanded' : ''
                    }}
                >
                    <span className={`menuTitle ${expanded === 'panel2' ? 'selected' : ''}`}><img src={titleIcon2} /> Courses</span>
                    {expanded === 'panel2' && <Arrow></Arrow>}
                </AccordionSummary>
                <AccordionDetails className='details'>
                    <MenuItem isSelected={currentItem === 'my-courses'} onClick={() => handleItemClick('my-courses')}>
                        <img src={jobSearchIcon} className='icon' />
                        My Courses
                    </MenuItem>

                    <MenuItem isSelected={currentItem === 'all-courses'} onClick={() => handleItemClick('all-courses')}>
                        <img src={appliedJobIcon} className='icon' />
                        All Courses
                    </MenuItem>

                </AccordionDetails>
            </Accordion>

            <span className='hrLine'></span>
            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} style={{ width: '100%', boxShadow: 'none', margin: '0' }} classes={{ root: 'custom-accordion-root' }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel4bh-content"
                    id="panel4bh-header"
                    classes={{
                        root: 'custom-accordion-summary-root',
                        expanded: expanded === 'panel4' ? 'expanded' : ''
                    }}
                >
                    <span className={`menuTitle ${expanded === 'panel4' ? 'selected' : ''}`}><img src={titleIcon4} /> Practice Test</span>
                    {expanded === 'panel4' && <Arrow></Arrow>}
                </AccordionSummary>
                <AccordionDetails className='details'>
                    <MenuItem isSelected={currentItem === 'completed-tests'} onClick={() => {
                        handleItemClick('completed-tests')
                    }}>
                        <img src={interviewDashboardIcon} className='icon' />
                        Completed Practice Tests
                    </MenuItem>
                    <MenuItem isSelected={currentItem === 'practice-test'} onClick={() => {
                        handleItemClick('practice-test')
                    }}>
                        <img src={practiceInterviewIcon} className='icon' />
                        <a>Take Practice Test</a>
                    </MenuItem>
                </AccordionDetails>
            </Accordion>

            <span className='hrLine'></span>
            <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')} style={{ width: '100%', boxShadow: 'none', margin: '0', border: 'none' }} classes={{ root: 'custom-accordion-root' }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel5bh-content"
                    id="panel5bh-header"
                    classes={{
                        root: 'custom-accordion-summary-root',
                        expanded: expanded === 'panel5' ? 'expanded' : ''
                    }}
                >
                    <span className={`menuTitle ${expanded === 'panel5' ? 'selected' : ''}`}><img src={titleIcon5} /> Profile</span>
                    {expanded === 'panel5' && <Arrow></Arrow>}
                </AccordionSummary>
                <AccordionDetails className='details'>
                    <MenuItem isSelected={currentItem === 'profile'} onClick={() => handleItemClick('profile')}>
                        <img src={profileIcon} className='icon' />
                        Profile
                    </MenuItem>
                    {/* <MenuItem isSelected={currentItem === 'subscriptions'} onClick={() => handleItemClick('subscriptions')}>
            <img src={subsIcon} className='icon' />
            Subscriptions
          </MenuItem> */}
                </AccordionDetails>
            </Accordion>


            <span className='hrLine'></span>
            <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')} style={{ width: '100%', boxShadow: 'none', margin: '0' }} classes={{ root: 'custom-accordion-root' }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel6bh-content"
                    id="panel6bh-header"
                    classes={{
                        root: 'custom-accordion-summary-root',
                        expanded: expanded === 'panel6' ? 'expanded' : ''
                    }}
                >
                    <span className={`menuTitle ${expanded === 'panel6' ? 'selected' : ''}`}><img src={titleIcon6} /> Support</span>
                    {expanded === 'panel6' && <Arrow></Arrow>}
                </AccordionSummary>
                <AccordionDetails className='details'>
                    <MenuItem isSelected={currentItem === 'call-support'} onClick={() => handleItemClick('call-support')}>
                        <img src={callSupportIcon} className='icon' />
                        Contact Support
                    </MenuItem>
                </AccordionDetails>
            </Accordion>

        </Container >
    );
};

export default AcademicSidebar;
