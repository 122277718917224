import React, { useState, useEffect } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {AuthenticationConstants} from '../../utils/constants';
import { isAuthenticated } from '../../utils/isAuthenticated';
import useTokenExpiry from '../globalComponents/useTokenExpiry';
import SessionExpiredModal from '../globalComponents/SessionExpiredModal';
import { useDispatch } from 'react-redux';
import { logout } from '../../slices/authSlice';
import { persistor } from '../../store';

const ProtectedRoute = ({ role }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const accessToken = useSelector(state => state.auth.userData?.accessToken);
    const { isSessionExpired } = useTokenExpiry(accessToken);
    const [isModalVisible, setIsModalVisible] = useState(false);

    useEffect(() => {
        setIsModalVisible(isSessionExpired);
    }, [isSessionExpired]);

    const handleLogout = () => {
        setIsModalVisible(false);
        persistor.purge();
        dispatch(logout())
        navigate("/login", { replace: true, state: { isFixed: true } });
    };

    let currentUser = isAuthenticated();

    if (currentUser === AuthenticationConstants.deny) {
        toast.warn("Please login to access this resource");
        return <Navigate to='/login' />;
    }

    if (role !== "basic" && role !== currentUser) {
        return <Navigate to='/access-denied' />;
    }

    return (
        <>
            {isModalVisible && <SessionExpiredModal handleLogout={handleLogout} />}
            <Outlet />
        </>
    );
};

export default ProtectedRoute;
