import React from "react";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { updateTest } from "../../../slices/academicTestSlice";
import Favicon from "react-favicon";
import testFavicon from "../../../assets/Academic/examPrep.ico"


const style = {
    backgroundColor: 'var(--backgroundColor)',
    color: 'var(--academicLavendar)',
    position: 'fixed',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '1.4rem',
    justifyContent: 'center',
    alignItems: 'center',
    left: 0,
    top: 0,
}

function CreateTest() {
    const { testState } = useParams();
    const stringsArray = ['Analyzing Topics', 'Creating Questions', 'Creating Test', 'Preparing Test'];

    const [currentString, setCurrentString] = useState(stringsArray[0]);
    const { testId } = useParams();
    const accessToken = useSelector(state => state.auth.userData?.accessToken);

    const testData = useSelector((state => state?.academicTest?.testData));
    const testErrorData = useSelector((state => state?.academicTest?.error));
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        let currentIndex = 1;
        const intervalId = setInterval(() => {
            setCurrentString(stringsArray[currentIndex]);
            currentIndex++;

            if (currentIndex === stringsArray.length) {
                clearInterval(intervalId);
                setCurrentString(stringsArray[stringsArray.length - 1])
            }
        }, 10000);

        dispatch(updateTest({ id: testId, status: "started", accessToken }))

        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        if (testData?.status == "STARTED") {
            navigate(`/academic/ongoing-test/${testId}/${testState}`)
        }
        else if (testErrorData) {
            toast.warn(testErrorData);
            toast.warn(testErrorData);
            navigate("/dashboard/academic");
        }
    }, [testData])
    return (
        <Box sx={style}>
            <Favicon url={testFavicon}/>
            <CircularProgress color="inherit" />
            <span style={{ color: 'var(--color)' }}>{currentString}</span>
        </Box>
    );
}

export default CreateTest;



