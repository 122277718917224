import axios from "axios"

export const academicRegister = async (email, firstName, password) => {
    const requestData = {
        email,
        firstName,
        password
    };

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'x-client-code': 'academic'
        }
    };

    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/signup`, requestData, config);
        return response.data;
    } catch (error) {
        throw error;
    }
}