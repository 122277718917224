import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import metric1 from '../../assets/icons/metric2.png'
import metric2 from '../../assets/icons/metric2.1.png'
import metric3 from '../../assets/icons/metric2.1.png'
import metric4 from '../../assets/icons/metric2.3.png'

import { useDispatch } from 'react-redux';
import { setCurrentMetric } from '../../slices/configSlice';
import { toast } from 'react-toastify';
import { CircularProgress } from '@mui/material';
import { getUserSettingByKey } from '../../functions/api/configSettings/getUserSettingByKey';
import CompletedTests from './SidebarPages/CompletedTests';
import MyPerformance from './SidebarPages/MyPerformance';
import AllCourses from './SidebarPages/AllCourses';
import MyCourses from './SidebarPages/MyCourses';


const MainContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
gap: 2rem;
width: 98%;
padding: 0 1%;


`

const Container = styled.div`
display: flex;
flex-direction: row;
width: 94%;
justify-content: space-between;
align-items: center;
padding: 0rem 0% 0rem 0%;
gap: 2%;


.selected {
    background: rgb(206,203,239);
background: linear-gradient(0deg, rgba(206,203,239,1) 0%, rgba(239,236,247,1) 100%);
}

  .achievedNumberBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 1.7rem;
    background-color: var(--white);
    padding: 1rem 0 1.5rem 0;
    width: 22.5%;
    height: 6rem;
    border-radius: 0.5rem;
    box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.5);


    .top {
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      align-items: center;

      img {
        width: 3rem;
        height: 3rem;
      }
    }
  }

  .achievedNumberBox:hover {
    cursor: pointer;
  }
  
  .achievedNumberDigit {
    font-size: 2.5rem;
    font-weight: 700;
    color: var(--color);
  }
  
  .achievedNumberText {
    font-size: 0.9rem;
    font-weight: 600;
    color: var(--color);
    text-align: center;
  } 

  .hrLine {
    width: 100%;
    border-top: 0.1rem groove lightgrey;
    margin: -0.2rem 0 -0.9rem 0;
    box-shadow: 0 0.5px 0.5px rgba(0, 0, 0, 0.25);
  }

  .loaderBox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% - 17rem);
    height: calc(100% - 4.1rem);
    background-color: #f4f4f4;
    position: absolute;
    z-index: 1000;
    right: 0;
    top: 4.1rem;
    color: var(--academicLavendar);
  
  }
`;


const AcademicMetrics = () => {
    const dispatch = useDispatch();
    const currentMetric = useSelector((state) => state.config?.currentMetric);
    const accessToken = useSelector(state => state.auth.userData?.accessToken)
    const clientCode = useSelector(state => state.auth.userData?.user?.clientCode);

    const [dashboardMetrics, setDashboardMetrics] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const getDashboardConfiguration = async () => {
            try {
                setLoading(true);
                const dashboard = await getUserSettingByKey(accessToken, clientCode, "academicDashboardMetrics")

                if (dashboard) {
                    setDashboardMetrics(dashboard?.data?.itemValue)
                    setLoading(false)
                }

            } catch (error) {
                const errMsg =
                    error?.response?.data?.notify?.message ||
                    "An error occurred. Please try again.";
                toast.error(errMsg, 8000);
                setLoading(false)
            }
        }
        getDashboardConfiguration()
    }, []);


    const handleCurrentMetricChange = (metric) => {
        dispatch(setCurrentMetric(metric));
    }


    const getMetricDisplayText = (text) => {
        switch (text) {
            case "completedTests":
                return "Completed Practice Tests";
            case "allCourses":
                return "All Courses";
            case "myCourses":
                return "My Courses";
            case "myPerformance":
                return "My Performance";
            default:
                return "";
        }
    }


    const getMetricImage = (text) => {
        switch (text) {
            case "completedTests":
                return metric1;
            case "allCourses":
                return metric2;
            case "myCourses":
                return metric3;
            case "myPerformance":
                return metric4;
            default:
                return "";
        }
    }

    const getMetricCount = (text) => {
        switch (text) {
            case "completedTests":
                return 0;
            case "allCourses":
                return 0;
            case "myCourses":
                return 0;
            case "myPerformance":
                return 0;
            default:
                return 0;
        }
    }


    return (
        <MainContainer>
            <Container>
                {loading ? <div className='loaderBox'>
                    <CircularProgress color="inherit" />
                </div> :
                    dashboardMetrics?.split(",")?.map((metric, i) => (
                        <div className={`achievedNumberBox ${currentMetric === metric ? 'selected' : ''}`} onClick={() => handleCurrentMetricChange(metric)}>
                            <div className='top'>
                                <img src={getMetricImage(metric)} />
                                <span className='achievedNumberDigit'>{getMetricCount(metric)}</span>
                            </div>
                            <span className='hrLine'></span>
                            <span className='achievedNumberText'>{getMetricDisplayText(metric)}</span>
                        </div>
                    ))
                }
            </Container>

            {currentMetric === 'completedTests' && <CompletedTests />}
            {currentMetric === 'allCourses' && <AllCourses />}
            {currentMetric === 'myCourses' && <MyCourses />}
            {currentMetric === 'myPerformance' && <MyPerformance />}
        </MainContainer>
    );
};


export default AcademicMetrics;
